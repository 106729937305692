import React, { useState, useCallback, useEffect } from "react";
import { google } from "../../config";

import {
	Card,
	CardHeader,
	CardBody,
	Col,
	Container,
	Row,
	Nav, 
	NavItem, 
	NavLink,
	TabContent,
	TabPane
} from "reactstrap";

import classnames from "classnames";

import { Link } from 'react-router-dom';

import { useParams } from "react-router-dom";
import Loader from "../../Components/Commons/Loader";

import * as moment from "moment";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import Masonry from "react-masonry-component";

import notFoundImage from "../../images/not_found.png";

import OrderStatusPublic from "../OrderSets/OrderStatusPublic";
import OptirouteOrderStatus from "../OrderSets/OptirouteOrderStatus";
import OrderStatusIcon from "../OrderSets/OrderStatusIcon";
import EmptyState from "../../Components/Commons/EmptyState";

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

import {
	useGetPublicOrderSetByIdQuery
} from '../../api/api';

const ClientsTrackingDetails = () => {

	document.title = "Seguimiento en línea de pedidos PROGISTICA";
	
	let { id } = useParams();

	const { 
		data: orderSet,
		error,
		isFetching: orderSetLoading,
		isSuccess: orderSetSuccess
	} = useGetPublicOrderSetByIdQuery(
		id ? id : '',
		{
			refetchOnMountOrArgChange: true,
			skip: !id,
		}
	);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	// Vertical Nav Tabs
    const [verticalTab, setverticalTab] = useState("1");
    const toggleVertical = (tab) => {
        if (verticalTab !== tab) {
            setverticalTab(tab);
        }
    };
	
	document.title = "Tracking de pedidos PROGISTICA";

	window.onscroll = function () {
		scrollFunction();
	};

	const scrollFunction = () => {
		const element = document.getElementById("back-to-top");
		if (element) {
			if (
				document.body.scrollTop > 100 ||
				document.documentElement.scrollTop > 100
			) {
				element.style.display = "block";
			} else {
				element.style.display = "none";
			}
		}
	};

	useEffect(() => {
		if(orderSet){
			if(orderSet.waypoints && orderSet.waypoints.length > 0){
				debugger;
				toggleVertical(orderSet.waypoints[0].id);
			}
		}
	  }, [orderSet]);

	return (
		<div className="page-content">
			<Container fluid>
				<Row>
					<Col lg={12}>
						<Card>
							<CardBody>
								<Row className="gx-lg-5 p-4">
								<Col xl={12}>
									{orderSetLoading && <Loader/>}
									{orderSetSuccess && (
										<div className="mt-xl-0">
											<div className="d-flex mt-2">
												<div className="flex-grow-1">
													<div className="hstack gap-3 flex-wrap">
														<h1 className="p-0">Pedido #{orderSet.reference}</h1>
														<div className="d-flex flex-wrap gap-2 align-items-center">
															<h4>
																<OrderStatusPublic
																	status={orderSet.status}
																	status_text={orderSet.status_text}/>
															</h4>
															{/*<div className="d-flex flex-wrap fs-12 text-muted gap-4">
																{"("}
																actualización: 
																{" "}
																{handleValidDate(orderSet.updated_at)}
																{" - "}
																{handleValidTime(orderSet.updated_at)}
																{")"}
															</div>*/}
														</div>
													</div>
													<div className="hstack gap-3 flex-wrap mt-0">

														<div className="fs-14 text-muted">
															Cliente :{" "}
															<span className="text-body fw-medium">
																{orderSet.customer.name}
															</span>
														</div>

														<div className="vr"></div>

														<div className="fs-14 text-muted">
															CC :{" "}
															<span className="text-body fw-medium">
																{orderSet.customer.reference}
															</span>
														</div>

														<div className="vr"></div>

														<div className="fs-14 text-muted">
															Zona:{" "}
															<span className="text-body fw-medium">
																{orderSet.zone}
															</span>
														</div>
													</div>
												</div>
											</div>
										
										</div>
									)}
									{error && (
										<EmptyState
											image={notFoundImage}
											title={"Pedido no encontrado"}
											description={"Verifica el número de pedido e intenta nuevamente"}
										/>
									)}
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>

				<Row>
					<Col xl={5}>
						{orderSetLoading && <Loader/>}
						{orderSetSuccess && (
							<>
								<Card className="mb-3">
									<CardHeader className="align-items-center d-flex">
										<h4 className="card-title mb-0 flex-grow-1">Eventos</h4>
									</CardHeader>
									<CardBody>
										{orderSet.order_events && orderSet.order_events.map((event, key) => (
											<div className="accordion-item border-0" key={key}>
												<div className="accordion-header p-2 accordion-button shadow-none" id="headingOne">
													<div className="d-flex align-items-center">
														<div className="flex-shrink-0 avatar-xs">
															<div className={`avatar-title rounded-circle ${event.status === 1 ? 'bg-success' : 'bg-light text-danger'}`}>
																<i className={`${OrderStatusIcon({status: event.event.id})}`}></i>
															</div>
														</div>
														<div className="flex-grow-1 ms-3">
															<div>
																<span className="fs-15 mb-0 fw-medium">
																	{event.event.name}
																</span>
																{(event.event.id === 6 || event.event.id === 7 || event.event.id === 8 || event.event.id === 5) && (
																	<span className="fw-normal ms-2">
																		-
																		{' '}
																		{handleValidDate(event.datetime)} {handleValidTime(event.datetime)}
																	</span>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
									</CardBody>
								</Card>
							
								<Card className="mb-3">
									<CardHeader className="align-items-center d-flex">
										<h4 className="card-title mb-0 flex-grow-1">Información pedido</h4>
									</CardHeader>
									<CardBody>
										
										<div className="table-responsive table-card">
											<table className="table mb-0">
												<tbody>
													<tr>
														<th scope="row">Facturación</th>
														<td>{handleValidDate(orderSet.billing_date)}</td>
													</tr>
													<tr>
														<th scope="row">Campaña</th>
														<td>{orderSet.campaign_reference}</td>
													</tr>
													<tr>
														<th scope="row">Cantidad de cajas</th>
														<td>{orderSet.box_quantity}</td>
													</tr>
													<tr>
														<th scope="row">Territorio</th>
														<td>{orderSet.territory}</td>
													</tr>
													<tr>
														<th scope="row">Zona</th>
														<td>{orderSet.zone}</td>
													</tr>
													<tr>
														<th scope="row">Sección</th>
														<td>{orderSet.section}</td>
													</tr>
													<tr>
														<th scope="row">Cantidad de premios</th>
														<td>{orderSet.quantity}</td>
													</tr>
													<tr>
														<th scope="row">Descripción Premio</th>
														<td>
															{orderSet.out_of_boxes.map((out_of_box, key) => {
																return out_of_box.quantity + ' x ' + out_of_box.afp_name + ', ';
															})}
														</td>
													</tr>
													<tr>
														<th scope="row">Total Bultos</th>
														<td>{orderSet.box_quantity + orderSet.quantity}</td>
													</tr>
													<tr>
														<th scope="row">Nombre Cliente</th>
														<td>{orderSet.customer.name}</td>
													</tr>
													<tr>
														<th scope="row">Código Cliente</th>
														<td>{orderSet.customer.reference}</td>
													</tr>
													<tr>
														<th scope="row">Dirección Cliente</th>
														<td>{orderSet.address.address_string}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</CardBody>
								</Card>

								<Card className="mb-3">
									<CardHeader className="align-items-center d-flex">
										<h4 className="card-title mb-0 flex-grow-1">Ubicación de entrega</h4>
									</CardHeader>
									<CardBody>
										{<iframe
											width="100% !important"
											height="250"
											src={"https://www.google.com/maps/embed/v1/place?key=" + google.API_KEY + "&q=" + orderSet.address.latitude + "," + orderSet.address.longitude + "&zoom=16"}>
										</iframe>}
									</CardBody>
								</Card>
							</>
						)}
					</Col>
					<Col xl={7}>
						{orderSetLoading && <Loader/>}
						{orderSetSuccess && orderSet.waypoints && orderSet.waypoints.length > 0 && (
							<Card className="mb-3">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">Intentos de Entrega</h4>
								</CardHeader>
								<CardBody>
									
									<Row>
										<Col md={3}>
											<Nav pills className="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills text-center" id="v-pills-tab">
												{(orderSet.waypoints && orderSet.waypoints.map(( waypoint, key) => (
													<NavItem key={key}>
														<NavLink
															style={{ cursor: "pointer" }}
															className={classnames({
																"mb-2": true,
																active: verticalTab === waypoint.id,
															})}
															onClick={() => {
																toggleVertical(waypoint.id);
															}}
															id="v-pills-home-tab"
														>
															{moment(waypoint.completed_at ? waypoint.completed_at : waypoint.departure_datetime).format("DD/MM/YY")}
														</NavLink>
													</NavItem>
												)))}
											</Nav>
										</Col>
										<Col md={9}>
											<TabContent
												activeTab={verticalTab}
												className="text-muted mt-4 mt-md-0"
												id="v-pills-tabContent"
											>
												{(orderSet.waypoints && orderSet.waypoints.map(( waypoint, key) => (
													<TabPane key={key} tabId={waypoint.id} id="v-pills-home">
														<h6 className="mb-2">Recepción</h6>											
														<div className="table-responsive table-bordered">
															<table className="table mb-0">
																<tbody>
																	<tr>
																		<th scope="row">Estado</th>
																		<td><OptirouteOrderStatus
																			status={waypoint.status}
																			status_text={waypoint.status_text}/>
																		</td>
																	</tr>
																	{(
																		waypoint.status == 2 && (
																			<>
																				<tr>
																					<th scope="row">Nombre receptor</th>
																					<td>{waypoint.reception_name}</td>
																				</tr>
																				<tr>
																					<th scope="row">RUT receptor</th>
																					<td>{waypoint.reception_rut}</td>
																				</tr>
																			</>
																		)
																	)}
																	{(
																		(waypoint.status == 2 || waypoint.status == 3) && (
																			<tr>
																				<th scope="row">Comentario de entrega</th>
																				<td>{waypoint.note}</td>
																			</tr>
																		)
																	)}
																	
																</tbody>
															</table>
														</div>
														{(waypoint.images_urls && waypoint.images_urls.length > 0 && (
															<>
																<h6 className="mt-4 mb-1">Imágenes</h6>
																<Masonry className="row gallery-wrapper">
																	{(waypoint.images_urls && waypoint.images_urls.map(( img, key) => (
																		<Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={key}>
																			<Card className="gallery-box">
																				<div className="gallery-container">
																					<Link className="image-popup" to={img} title="">
																						<img className="gallery-img img-fluid mx-auto" src={img} alt="" />
																						<div className="gallery-overlay">
																							<h5 className="overlay-caption"></h5>
																						</div>
																					</Link>
																				</div>

																			</Card>
																		</Col>
																	)))}
																</Masonry>
															</>
														))}
														{(waypoint.last_location && (
															<>
																<h6 className="mt-2 mb-1">Posición efectiva de intento</h6>
																<iframe
																	width="100% !important"
																	height="250"
																	src={"https://www.google.com/maps/embed/v1/place?key=" + google.API_KEY + "&q=" + waypoint.last_location.latitude + "," + waypoint.last_location.longitude + "&zoom=16"}>
																</iframe>
															</>
														))}
													</TabPane>
												)))}
											</TabContent>
										</Col>
									</Row>
									
								</CardBody>
							</Card>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ClientsTrackingDetails;