import React, { useEffect, useState, useMemo, useCallback } from "react";
import { withTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Form,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	FormFeedback,
	Nav, 
	NavItem, 
	NavLink,
  Input,
	TabContent,
	TabPane
} from "reactstrap";
import OptirouteOrderStatus from "../OrderSets/OptirouteOrderStatus";

import classnames from "classnames";
import * as moment from "moment";
import { google } from "../../config";

import { hasGroup } from "../../api/session_helper";
import { Link, useParams } from "react-router-dom";
import OrdersTable from "./OrdersTable";
import Loader from "../../Components/Commons/Loader";
import CollectTicketsTable from "./CollectTicketsTable";
import OutOfBoxTable from "./OutOfBoxTable";
import Masonry from "react-masonry-component";

import 'react-toastify/dist/ReactToastify.css';

import { useGetOrderSetByIdQuery, useCreateOutOfBoxMutation } from '../../api/api'

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";



const OrderSetOrders = ({ t, i18n }) => {

  let { id } = useParams();
  const [createModal, setCreateModal] = useState(false);

	const [verticalTab, setverticalTab] = useState("1");
    const toggleVertical = (tab) => {
        if (verticalTab !== tab) {
            setverticalTab(tab);
        }
    };

  const toogle = useCallback(() => {
    if (createModal) {
      setCreateModal(false);
      validation.resetForm();
    } else {
      setCreateModal(true);
    }
  }, [createModal]);

  const [
		createOutOfBox,
		{ 
			error: outOfBoxerrors,
			isLoading: isUpdateLoading,
      isSuccess: isOutOfBoxSuccess
		}] = useCreateOutOfBoxMutation();

  const { 
    data: orderSet,
    error,
    isFetching: isOrderSetLoading,
    isSuccess: isOrderSetSuccess
  } = useGetOrderSetByIdQuery(
    id,
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  useEffect(() => {
    if (isOutOfBoxSuccess) {
      toogle();
    }
  }, [isOutOfBoxSuccess]);

  useEffect(() => {
    if (orderSet) {
      if(orderSet.waypoints && orderSet.waypoints.length > 0){
				toggleVertical(orderSet.waypoints[0].id);
			}
    }
  }, [orderSet]);

  const validation = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,

		initialValues: {
			user_id: '',
			datetime: '',
			event_id: '',
		},

		validationSchema: Yup.object({
			afp_name: Yup.string().required("Please Enter a Name"),
			quantity: Yup.number().moreThan(-1).required(t("Please enter a quantity"))
		}),
		onSubmit: (values) => {
			const outOfBox = {
				afp_name: values["afp_name"],
				quantity: values["quantity"],
        order_set_id: id
			};
			createOutOfBox(outOfBox);
		},
	});

  document.title = "Sistema de pedidos PROGISTICA";

  return (
		<React.Fragment>
      <div className="modal fade" id="addmembers" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={createModal} centered>
            <ModalHeader>{t("Create Out of Box")}</ModalHeader>
            <ModalBody>
              <Form onSubmit={validation.handleSubmit}>
                <Row>
                  <Col lg={8}>
                    <div className="mb-3">
                      <Label htmlFor="afp_name" className="form-label">{t("AFP Name")}</Label>
                      <Input type="text" className="form-control" id="afp_name" placeholder="AFP Name"
                        name='afp_name'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.afp_name || ""}
                        invalid={
                          (validation.errors.afp_name) || (outOfBoxerrors && outOfBoxerrors.afp_name) ? true : false
                        }
                      />
                      {validation.touched.afp_name && validation.errors.afp_name ? (
                        <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                      ) : null}
                      {outOfBoxerrors && outOfBoxerrors.afp_name ? (<FormFeedback type="invalid">{outOfBoxerrors.afp_name}</FormFeedback>) : null}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="quantity" className="form-label">{t("Quantity")}</Label>
                      <Input type="number" className="form-control" id="quantity" placeholder={t("Quantity")}
                        name='quantity'
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.quantity || ""}
                        invalid={
                          (validation.errors.quantity) || (outOfBoxerrors && outOfBoxerrors.quantity) ? true : false
                        }
                      />
                      {validation.touched.quantity && validation.errors.quantity ? (
                        <FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
                      ) : null}
                      {outOfBoxerrors && outOfBoxerrors.quantity ? (<FormFeedback type="invalid">{outOfBoxerrors.quantity}</FormFeedback>) : null}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                      <button type="button" className="btn btn-light" onClick={() => toogle()}>{t("Close")}</button>
                      <button type="submit" className="btn btn-success" id="addNewOutOfBox">{t("Create")}</button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      {isOrderSetLoading && <Loader/>}
      {isOrderSetSuccess && (
        <Card id="orderList">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col-sm">
                <h5 className="card-title mb-0">{t("Boxes")}</h5>

              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <OrdersTable
              data={(orderSet && orderSet.order_set_orders || [])}
              isLoading={isOrderSetLoading}
              isSuccess={isOrderSetSuccess}
              error={error}
              isGlobalFilter={false}
              customPageSize={25}
              divClass="table-responsive table-card mb-1"
              tableClass="align-middle table-nowrap"
              theadClass="table-light text-muted"
            />
          </CardBody>
        </Card>
      )}
      {isOrderSetLoading && <Loader/>}
      {isOrderSetSuccess && (
        <Card id="outOfBoxesList">
          <CardHeader>
            <div className="d-flex align-items-center">
              <h5 className="card-title flex-grow-1 mb-0">{t("Out of Boxes")}</h5>
              {hasGroup(['Manager', 'OperationalManager', 'RouteManager', 'ReverseLogisticsOperator']) && (
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => { toogle(); }}
                  >
                    {t("New Out of Box")}
                  </button>
                </div>
              )}
            </div>
          </CardHeader>
          <CardBody>
            <OutOfBoxTable
              data={orderSet.out_of_boxes}
              isLoading={isOrderSetLoading}
              isSuccess={isOrderSetSuccess}
              error={error}
              isGlobalFilter={false}
              customPageSize={25}
              divClass="table-responsive table-card mb-1"
              tableClass="align-middle table-nowrap"
              theadClass="table-light text-muted"
            />
            {/*<ul className="list-group">
              {orderSet.out_of_boxes.map((item, key) => (
                <li className="list-group-item" key={key}>{item.afp_name}</li>
              ))}
            </ul>*/}
          </CardBody>
        </Card>
      )}
      {isOrderSetLoading && <Loader/>}
      {isOrderSetSuccess && !hasGroup(['OutOfBoxOperator']) && (
        <Card id="ticketList">
          <CardHeader className="align-items-center d-flex">
            <h5 className="card-title mb-0 flex-grow-1">{t("Collect Ticket Information")}</h5>
            {hasGroup(['Manager', 'OperationalManager', 'RouteManager', 'ReverseLogisticsOperator']) && (
              <div className="flex-shrink-0">
                <Link
                  to={`create-collect-ticket`}
                  className="btn btn-success btn-sm"
                >
                  <i className="ri-pencil-fill align-middle me-1"></i>{" "}
                  {t("Create Collect Ticket")}
                </Link>
              </div>
            )}
          </CardHeader>
          <CardBody>
            <CollectTicketsTable
              data={orderSet.collect_tickets}
              isLoading={isOrderSetLoading}
              isSuccess={isOrderSetSuccess}
              error={error}
              isGlobalFilter={false}
              customPageSize={25}
              divClass="table-responsive table-card mb-1"
              tableClass="align-middle table-nowrap"
              theadClass="table-light text-muted"
            />
          </CardBody>
        </Card>
      )}
    {/*isOrderSetSuccess && orderSet.images_urls && orderSet.images_urls.length > 0 && (
        <Card>
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">{t("Images")}</h4>
            </CardHeader>

            <CardBody>

                <Row>
                    <Col lg={12}>
                        <Masonry className="row gallery-wrapper">
                            {(isOrderSetSuccess && orderSet.images_urls.map(( img, key) => (
                                <Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={key}>
                                    <Card className="gallery-box">
                                        <div className="gallery-container">
                                            <Link className="image-popup" to={img} title="">
                                                <img className="gallery-img img-fluid mx-auto" src={img} alt="" />
                                                <div className="gallery-overlay">
                                                    <h5 className="overlay-caption"></h5>
                                                </div>
                                            </Link>
                                        </div>

                                    </Card>
                                </Col>
                            )))}

                        </Masonry>
                    </Col>
                </Row>

            </CardBody>
        </Card>
    )*/}

		{isOrderSetSuccess && orderSet.waypoints && orderSet.waypoints.length > 0 && (
				<Card className="mb-3">
					<CardHeader className="align-items-center d-flex">
						<h4 className="card-title mb-0 flex-grow-1">{t("Deliveries tries")}</h4>
					</CardHeader>
					<CardBody>
						
						<Row>
							<Col md={3}>
								<Nav pills className="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills text-center" id="v-pills-tab">
									{(orderSet.waypoints && orderSet.waypoints.map(( waypoint, key) => (
										<NavItem key={key}>
											<NavLink
												style={{ cursor: "pointer" }}
												className={classnames({
													"mb-2": true,
													active: verticalTab === waypoint.id,
												})}
												onClick={() => {
													toggleVertical(waypoint.id);
												}}
												id="v-pills-home-tab"
											>
												{moment(waypoint.completed_at ? waypoint.completed_at : waypoint.departure_datetime).format("DD/MM/YY")}
											</NavLink>
										</NavItem>
									)))}
								</Nav>
							</Col>
							<Col md={9}>
								<TabContent
									activeTab={verticalTab}
									className="text-muted mt-4 mt-md-0"
									id="v-pills-tabContent"
								>
									{(orderSet.waypoints && orderSet.waypoints.map(( waypoint, key) => (
										<TabPane key={key} tabId={waypoint.id} id="v-pills-home">
											<h6 className="mb-2">{t("Reception")}</h6>											
											<div className="table-responsive table-bordered">
												<table className="table mb-0">
													<tbody>
                            <tr>
                              <th scope="row">Estado</th>
                              <td><OptirouteOrderStatus
                                status={waypoint.status}
                                status_text={waypoint.status_text}/>
                              </td>
                            </tr>
                            {(
                              waypoint.status == 2 && (
                                <>
                                  <tr>
                                    <th scope="row">Nombre receptor</th>
                                    <td>{waypoint.reception_name}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">RUT receptor</th>
                                    <td>{waypoint.reception_rut}</td>
                                  </tr>
                                </>
                              )
                            )}
                            {(
                              (waypoint.status == 2 || waypoint.status == 3) && (
                                <tr>
                                  <th scope="row">Comentario de entrega</th>
                                  <td>{waypoint.note}</td>
                                </tr>
                              )
                            )}
													</tbody>
												</table>
											</div>
											{(waypoint.images_urls && waypoint.images_urls.length > 0 && (
												<>
													<h6 className="mt-4 mb-1">{t("Images")}</h6>
													<Masonry className="row gallery-wrapper">
														{(waypoint.images_urls && waypoint.images_urls.map(( img, key) => (
															<Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={key}>
																<Card className="gallery-box">
																	<div className="gallery-container">
																		<Link className="image-popup" to={img} title="">
																			<img className="gallery-img img-fluid mx-auto" src={img} alt="" />
																			<div className="gallery-overlay">
																				<h5 className="overlay-caption"></h5>
																			</div>
																		</Link>
																	</div>

																</Card>
															</Col>
														)))}
													</Masonry>
												</>
											))}
											{(waypoint.last_location && (
												<>
													<h6 className="mt-2 mb-1">{t("Effective delivery location")}</h6>
													<iframe
														width="100% !important"
														height="250"
														src={"https://www.google.com/maps/embed/v1/place?key=" + google.API_KEY + "&q=" + waypoint.last_location.latitude + "," + waypoint.last_location.longitude + "&zoom=16"}>
													</iframe>
												</>
											))}
										</TabPane>
									)))}
								</TabContent>
							</Col>
						</Row>
						
					</CardBody>
				</Card>
			)}
		</React.Fragment>
  );
};

export default withTranslation()(OrderSetOrders);
