import React, {useEffect} from 'react';
import { Card, CardHeader, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Table } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { google } from "../../config";

import Loader from "../../Components/Commons/Loader";
import OrderStatus from "./OrderStatus";
import OrderSetKind from "./OrderSetKind";

import { useGetOrderSetByIdQuery } from '../../api/api'

import * as moment from "moment";

const OrderSetSummary = ({ t, i18n }) => {

	let { id } = useParams();

	const { 
		data: orderSet,
		error,
		isFetching: isOrderSetLoading,
		isSuccess: isOrderSetSuccess
	} = useGetOrderSetByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	useEffect(() => {
		if(orderSet){
			//debugger;
		}
	  }, [orderSet]);

	return (
		<React.Fragment>
			<Card className="mb-3">
				<CardHeader className="align-items-center d-flex">
					<h4 className="card-title mb-0 flex-grow-1">{t("Order Details")}</h4>
					<div className="flex-shrink-0">
						{isOrderSetLoading && <Loader/>}
						{isOrderSetSuccess && orderSet.kind === 1 && (
							<Link
								to={`edit`}
								className="btn btn-success btn-sm"
							>
								<i className="ri-pencil-fill align-middle me-1"></i>{" "}
								{t("Edit")}
							</Link>
						)}
					</div>
				</CardHeader>
				<CardBody>
					{isOrderSetLoading && <Loader/>}
					{isOrderSetSuccess && (
						<div className="table-responsive table-card">
							<Table className="table mb-0">
								<tbody>
									<tr>
										<td className="fw-medium text-muted">{t("Order Reference")}</td>
										<td className="overview-industryType">{orderSet.reference}</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Collect")}</td>
										<td className="overview-industryType">
											<OrderSetKind collect_ticket={orderSet.collect_ticket} />
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Status")}</td>
										<td className="overview-industryType">
											<OrderStatus status={orderSet.status} status_text={orderSet.status_text} />
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Billing day")}</td>
										<td className="overview-industryType">
											{handleValidDate(orderSet.billing_date)}
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("AFP Quantity")}</td>
										<td className="overview-company_location">
											{orderSet.quantity}
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Campaign")}</td>
										<td className="overview-company_location">
											{orderSet.campaign_reference}
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Territory")}</td>
										<td className="overview-company_location">
											{orderSet.territory}
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Zone")}</td>
										<td className="overview-company_location">
											{orderSet.zone}
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Section")}</td>
										<td className="overview-company_location">
											{orderSet.section}
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Box quantity")}</td>
										<td className="overview-company_location">
											{orderSet.box_quantity}
										</td>
									</tr>
									
								</tbody>
							
							</Table>
						</div>
					)}
				</CardBody>
			</Card>
			<Card>
				<CardHeader>
					<div className="d-flex">
						<h5 className="card-title flex-grow-1 mb-0">
							{t("Customer Details")}
						</h5>
					</div>
				</CardHeader>
				<CardBody>
					{isOrderSetLoading && <Loader/>}
					{isOrderSetSuccess && (
						<div className="table-responsive table-card">
							<Table className="table table-borderless">
								<tbody>
									<tr>
										<td className="fw-medium text-muted">{t("Name")}</td>
										<td className="overview-industryType">
											{orderSet.customer != null ? orderSet.customer.name : ''}
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Reference")}</td>
										<td className="overview-company_location">
											{orderSet.customer != null ? orderSet.customer.reference : ''}
										</td>
									</tr>
									<tr>
										<td className="fw-medium text-muted">{t("Address")}</td>
										<td className="overview-company_location">
											{orderSet.address != null ? orderSet.address.address_string : ''}
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					)}
				</CardBody>
			</Card>

			{isOrderSetSuccess && orderSet.address && orderSet.address.latitude && orderSet.address.longitude && (
				<Card className="mb-3">
					<CardHeader className="align-items-center d-flex">
						<h4 className="card-title mb-0 flex-grow-1">{t("Delivery location")}</h4>
					</CardHeader>
					<CardBody>
						{isOrderSetLoading && <Loader/>}
						{isOrderSetSuccess && (
							<iframe
								width="100% !important"
								height="250"
								src={"https://www.google.com/maps/embed/v1/place?key=" + google.API_KEY + "&q=" + orderSet.address.latitude + "," + orderSet.address.longitude + "&zoom=16"}>
							</iframe>
						)}
					</CardBody>
				</Card>
			)}
			
			<div className="card mb-3">
				<div className="card-body">
					<div className="d-flex mb-3">
						<h6 className="card-title mb-0 flex-grow-1">{t("Created By")}</h6>
					</div>
					{isOrderSetLoading && <Loader/>}
					{isOrderSetSuccess && (
						<ul className="list-unstyled vstack gap-3 mb-0">
							<li>
								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
									</div>
									{orderSet.user && (
										<div className="flex-grow-1 ms-2">
											<h6 className="mb-1"><Link to={`../users/${orderSet.user.id}`} >{orderSet.user.first_name} {orderSet.user.last_name}</Link></h6>
											<p className="text-muted mb-0">{orderSet.user.email}</p>
										</div>
									)}
								</div>
							</li>
						</ul>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(OrderSetSummary);